import React, { useState, useEffect, useRef } from 'react'
import { motion, useDomEvent, useSpring } from 'framer-motion'
import { useKeyPress } from '../utils/hooks'
import YouTube from 'react-youtube'
import styled from 'styled-components'

const transition = {
  type: 'spring',
  damping: 35,
  stiffness: 350,
}

export function Image({ post }) {
  const [isOpen, setOpen] = useState(false)
  useDomEvent(useRef(window), 'scroll', () => isOpen && setOpen(false))
  const escPressed = useKeyPress('Escape')
  if (escPressed && isOpen) setOpen(false)
  const url = post.media[0].url
  return (
    <Container className={`${isOpen ? 'open' : ''}`}>
      <motion.div
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={transition}
        className="shade"
        onTap={() => setOpen(false)}
      />
      <motion.img src={url} />
      <motion.img
        src={url}
        whileTap={{ scale: isOpen ? 1 : 0.95 }}
        onTap={() => setOpen(true)}
        layoutTransition={transition}
        draggable={isOpen}
        animate={{ borderRadius: isOpen ? 0 : 10 }}
      />
    </Container>
  )
}

export function Video(props) {
  if (props.post.provider === 'youtube') {
    return <YouTubeVideo {...props} />
  } else if (props.post.media.url) {
    return <NativeVideo {...props} />
  } else {
    console.error('Video post type not supported (yet?)')
    return null
  }
}

function YouTubeVideo({ post }) {
  const [isOpen, setOpen] = useState(false)
  useDomEvent(useRef(window), 'scroll', () => isOpen && setOpen(false))
  const escPressed = useKeyPress('Escape')
  if (escPressed && isOpen) setOpen(false)
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      version: 3,
      autoplay: 1,
      loop: 1,
      playlist: post.metadata.id,
    },
  }

  const volume = useSpring(0, { damping: 100 })
  function afterRender(ev) {
    ev.target.setVolume(0)
    volume.onChange(v => ev.target.setVolume(v * 100))
  }

  //TODO remove subscription
  volume.set(isOpen ? 1 : 0)
  return (
    <Container className={`${isOpen ? 'open' : ''}`}>
      <motion.div
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={transition}
        className="shade"
        onClick={() => setOpen(false)}
      />
      <div className="yt-placeholder">
        <YouTube videoId={post.metadata.id} />
      </div>
      <motion.div
        className="yt"
        whileTap={{ scale: !isOpen ? 0.95 : 1 }}
        onClick={() => setOpen(true)}
        layoutTransition={transition}
        animate={{ borderRadius: isOpen ? 0 : 10 }}>
        <YouTube videoId={post.metadata.id} opts={opts} onReady={afterRender} />
      </motion.div>
    </Container>
  )
}

function NativeVideo({ post }) {
  const [isOpen, setOpen] = useState(false)
  useDomEvent(useRef(window), 'scroll', () => isOpen && setOpen(false))
  const escPressed = useKeyPress('Escape')
  if (escPressed && isOpen) setOpen(false)
  let vid = useRef()
  const volume = useSpring(0, { damping: 100 })

  React.useEffect(() => {
    vid.current.volume = 0
    const unSubscribe = volume.onChange(v => (vid.current.volume = v))
    vid.current.play()
    return () => unSubscribe()
  }, [volume, vid])
  volume.set(isOpen ? 1 : 0)

  return (
    <Container className={`post-container ${isOpen ? 'open' : ''}`}>
      <motion.div
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={transition}
        className="shade"
        onClick={() => setOpen(false)}
      />
      <motion.video src={post.media.url} />
      <motion.video
        src={post.media.url}
        autoPlay
        ref={vid}
        controls={isOpen}
        whileTap={{ scale: !isOpen ? 0.95 : 1 }}
        onClick={() => setOpen(true)}
        layoutTransition={transition}
        animate={{ borderRadius: isOpen ? 0 : 10 }}
      />
    </Container>
  )
}

const Container = styled.div`
  img,
  video {
    max-width: calc(50vw - 4rem);
    max-height: calc(50vh - 4rem);
    margin: 1rem;
    &:nth-child(2) {
      position: absolute;
      pointer-events: none;
      opacity: 0;
    }
  }
  &:not(.open) .yt iframe {
    pointer-events: none;
  }
  .yt-placeholder {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    iframe {
      max-width: calc(50vw - 4rem);
      max-height: calc(50vh - 4rem);
    }
  }
  .yt,
  .yt-placeholder {
    max-width: calc(50vw - 4rem);
    max-height: calc(50vh - 4rem);
    margin: 1rem;
  }
  .yt {
    &.noclick iframe {
      pointer-events: none;
    }
    iframe {
      max-width: calc(50vw - 4rem);
      max-height: calc(50vh - 4rem);
      border-radius: 10px;
    }
  }
  &.open {
    .yt-placeholder {
      position: relative;
    }
    .yt {
      position: relative;
      z-index: 1;
      position: fixed;
      max-width: calc(50vw - 4rem);
      max-height: calc(50vh - 4rem);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      iframe {
        border-radius: 0px;
      }
    }
    video,
    img {
      position: relative;
      z-index: 1;
      &:last-child {
        position: fixed;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .shade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    background: rgba(255, 255, 255, 0.9);
  }
  &.open .shade {
    pointer-events: auto;
    opacity: 0.1;
    z-index: 1;
  }
`
