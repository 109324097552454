import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

export function Loader({ inViewCallback }) {
  const [ref, inView] = useInView({ rootMargin: window.innerHeight / 2 + 'px' })
  useEffect(() => {
    inViewCallback(inView)
  }, [inView])

  return <Container ref={ref}>Loading...</Container>
}

const Container = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
`
