import React, { useState, useEffect } from 'react'
import Posts from './components/Posts'
import { useGetPosts } from './utils/hooks'
import styled from 'styled-components'
import { Loader } from './components/Loader'

export default function App() {
  const [page, setPage] = useState(1)
  const [data, loading] = useGetPosts(page)
  const [posts, setPosts] = useState([])
  const [hasMorePosts, setHasMorePosts] = useState(true)

  function loaderInView(isInView) {
    if (isInView) {
      setPage(page + 1)
    }
  }

  useEffect(() => {
    if (data.length > 0) setPosts([...posts, ...data])
  }, [data])

  useEffect(() => {
    if (posts.length > 0 && data.length < 15) {
      setHasMorePosts(false)
    }
  }, [posts, data])

  return (
    <>
      <Container>
        <Posts data={posts} />
      </Container>
      {!loading && hasMorePosts && <Loader inViewCallback={loaderInView} />}
    </>
  )
}

const Container = styled.div`
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: calc(10px + 2vmin);
  color: #282c34;
`
