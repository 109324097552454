import { useState, useEffect } from 'react'

export function useFetch(url) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      const response = await fetch(url)
      const json = await response.json()
      setData(json)
      setLoading(false)
    })()
  }, [url])
  return [data, loading]
}

export function useGetPosts(page) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (window.getPosts) {
        window.getPosts(page).then(d => {
          setData(d)
          setLoading(false)
        })
      } else {
        const data = await fetch('https://cors-anywhere.herokuapp.com/https://mikkmartin.tumblr.com/page/' + page)
          .then(res => res.text())
          .then(raw => {
            var el = document.createElement('html')
            el.innerHTML = raw
            el = el.querySelector('script#posts')
            return eval(el.innerHTML)
          })
        setData(data)
        setLoading(false)
      }
    })()
  }, [page])
  return [data, loading]
}

export function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true)
      }
    }

    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    }
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targetKey]) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}
