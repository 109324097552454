import React from 'react'
import { Image, Video } from './Post'

export default function ({data}) {
    if (!data) return null
    return data.map(({content, trail}) => {
        if (content.length) return content[0]
        else return trail[0].content.find(({type}) => type === 'image' || type === 'video')
    }).map((post, i) => {
        switch (post.type) {
            case 'image':
                return <Image post={post} key={i} />
            case 'video':
                return <Video post={post} key={i} />
            default:
                return null
        }
    })
}